import { FunctionComponent, useCallback, useEffect, useState } from "react";
import TopNavbar from "src/components/TopNavbar";

import TopNavbarAdmin from "src/components/TopNavbarAdmin";
import {
  Button,
  Card,
  Container,
  Group,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
  useMantineTheme,
} from "@mantine/core"
import useAdminSettings from "src/hooks/useAdminSettings";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { FeedbackComponent } from "src/components/common/Feedback";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import useResponsive from "src/hooks/useResponsive";
import { FocusButton } from "src/components/common/Buttons/FocusButton";


const LLM_MODEL = [{label: 'gpt-4-turbo', value: "gpt-4-turbo"}, {label: "gpt-4-turbo-preview", value: "gpt-4-turbo-preview"}, {label: "gpt-3.5-turbo", value: "gpt-3.5-turbo"}]
const ConfigureLLM = () => {
  const {adminSettings, updateLLMConfig, error, feedback} = useAdminSettings()
  const [temperature, setTemperature] = useState(adminSettings?.llm_temperature.toString())
  const [role, setRole] = useState(adminSettings?.llm_role)
  const [model, setModel] = useState<string | null>(adminSettings.llm_model_name)
  const [prompt, setPrompt] = useState(adminSettings?.llm_prompt)
  const theme = useMantineTheme()
  const {backgroundColor, backgroundColorExterior} = useResponsive()

  useEffect(() => {
    setRole(adminSettings?.llm_role)
    setPrompt(adminSettings?.llm_prompt)
    setTemperature(adminSettings?.llm_temperature.toString())
  }, [adminSettings])

  const submitValues = () => [
    updateLLMConfig({
      llm_prompt: prompt,
      llm_temperature: Number(temperature),
      llm_role: role,
      llm_model_name: model,
    })
  ]

  return (
    <StyledContainer theme={{backgroundColor: backgroundColorExterior}}>
      <StyledContainerInset theme={{backgroundColor: backgroundColor}}>
    <Container p={"md"} fluid>
      <Text size={FONT_SIZES.h1} fw={600}>
        Configure LLM
      </Text>
      <Text c="dimmed" size={14}>
        Tailor assistant’s performance, role and behaviour according to your own
        requirements.
      </Text>

      <Card w={"60%"} bg={backgroundColor} withBorder my={"md"}>
        <Text fw={500}>Parameters</Text>

        <Group my={"md"} position="apart">
          <Select
            onChange={setModel}
            label="Model Selection"
            placeholder={adminSettings.llm_model_name}
            data={LLM_MODEL}
            value={model}
          />
          <TextInput 
          
          inputMode= "numeric"
          onChange={(event) => setTemperature(event.currentTarget.value)}
          value={temperature}
          label="Temperature" />
        </Group>

        <TextInput
          my={"md"}
          styles={(theme) => ({
            input: {
              marginTop: theme.spacing.xs,
            },
          })}
          onChange={(event) => setRole(event.currentTarget.value)}
          value={role}
          label="Role"
        />

        <TextInput
          styles={(theme) => ({
            input: {
              marginTop: theme.spacing.xs,
            },
          })}
          
          onChange={(event) => setPrompt(event.currentTarget.value)}
          value={prompt}
          label="Prompt"
        />
        <Space h={150} />
        <FocusButton onClick={submitValues} text="Save" iconSize="1.25rem" textSize={FONT_SIZES.h3} icon="save"/>
        
      </Card>
      {feedback ? (
          <FeedbackComponent
          error={error}
          feedback={feedback}
          />
        ) : null}
    </Container>
    </StyledContainerInset>
    </StyledContainer>
  );
};

export default ConfigureLLM;
