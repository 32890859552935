import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { COLORS } from "src/utils/styles";

const useResponsive = (query?: string) => {
    const isMobile = useMediaQuery(query ? query  : "(max-width: 992px)");
    const isTabletOnly = useMediaQuery(query ? query  : "(min-width: 425px)");
    const theme = useMantineTheme();
    const backgroundColor =
    theme.colorScheme == "dark" ? 'black' : theme.white;
  const backgroundColorExterior =
    theme.colorScheme == "dark" ? theme.colors.dark[7] : COLORS.bg;

    return {
        isMobile,
        backgroundColor,
        backgroundColorExterior,
        isTabletOnly
    }
}

export default useResponsive