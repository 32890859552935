import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/react/24/solid";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { Apple, EmailOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Center, Grid, Image, Stack, Text } from "@mantine/core";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";

const VerifyEmail: FunctionComponent = () => {
  const [verificationMessage, setVerificationMessage] = useState('');
  const url = window?.location?.href  
  const navigation = useNavigate();

  useEffect(() => {

    if(url.includes("token")){
        const urls = url.split("?");
        const breakTwo = urls[1].split('&')
        let token = breakTwo[0]
        let email = breakTwo[1]
        token = token.substring(token.indexOf('=') + 1);
        email = email.substring(email.indexOf('=') + 1)

        verifyEmail(token, email)
    }
  }, [url])

  const verifyEmail = async (token: string, email: string) => {
    const response = await authService.verifyEmail(token, email)
    
    if (response.kind == ResponseKind.OK) {
      setVerificationMessage("Email Verified. You can now proceed to login.");
      setTimeout(() => navigation("/auth/login"), 5000); // Redirect after 5 seconds
    } else {
      setVerificationMessage("Invalid Token or Link Expired.");
    }
  };

  return (
    <Center>
      <Grid h={"100vh"}>
        <Grid.Col sm={2} lg={2} />
        <Center>
          <Grid.Col sm={8} lg={8}>
            <Stack spacing={"lg"}>
            <Image src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"}/>
            <Text size={"lg"}>
            {verificationMessage || "Email verified!"}
            </Text>
              {verificationMessage && verificationMessage.startsWith("Email Verified") && (
                <Button
                variant="outline"
                onClick={() => navigation("/auth/login")}>
                  Sign In
                </Button>
              )}
            </Stack>

          </Grid.Col>
        </Center>
        <Grid.Col sm={2} lg={2} />
      </Grid>
    </Center>
  );
};

export default VerifyEmail;
