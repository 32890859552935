import { ActionIcon, Loader, Tooltip, useMantineTheme } from "@mantine/core";
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { ChatMode } from "src/enums/Chat";
import { optionButtons } from "./metadata";
import { useChat } from "src/hooks/useChat";
import { COLORS } from "src/utils/styles";
import Icon from "../common/Icons/Icons/Icon";

interface BotMessage {
    message: string
    index: number
    isLatest?: boolean
    streaming?: boolean
    traceless?: boolean
    toggleOptions?: () => void
    closeOptions?: () => void
    showOptions?: boolean
}

const BotMessage = (props: BotMessage) => {
    const {streaming, isLatest, traceless, toggleOptions = () => {}, closeOptions = () => {}, showOptions} = props
    const { sendMessageToBackend } = useChat()
    const theme = useMantineTheme()
    const [latest, setLatest] = useState(isLatest)
    const lastMessageRef = useRef<HTMLDivElement | null>(null)
    const clipboard = useClipboard({ timeout: 500 });

    useEffect(() => {
      setLatest(isLatest)
    }, [isLatest])

      const handleCopy = (textToCopy: string) => {
        clipboard.copy(textToCopy);
        showNotification({
          message: 'Text copied to clipboard',
          color: "grape",
        });
      };

    if(streaming && (!props?.message || props.message.length === 0)){
      return (<div className="inline-block text-center bg-chat-dialogue rounded-tr-lg rounded-b-lg p-2.5 mb-5 mx-auto">
      <Loader size={"md"} variant="dots" color="gray"/>
    </div>)
    }

    return (
    <div className = {(latest) ? 
      "flex flex-col items-start justify-start space-y-4 pt-0 pb-5 pl-0 mq800:max-w-[90%]" :
      "flex flex-col items-start justify-start space-y-4 pt-0 pb-0 pl-0 mq800:max-w-[90%]"}>
        <div className="rounded flex flex-col items-start justify-start pt-4 px-0 pb-0 box-border">
          
        <div className={`rounded-tl-none rounded-tr-lg rounded-b-lg ${traceless ? "bg-white-theme-button-border" : theme.colorScheme == "dark" ? "bg-color-theme-for-msg-dialogue" : " bg-chat-dialogue"} flex flex-col items-start justify-start py-0 px-0`}>
          {/* <div className="overflow-hidden flex flex-col items-start justify-start py-0 pr-3.5 pl-[15px]"> */}
            <div className={`text-sm xxs:text-xs p-4 py-2 font-chat-dante-ai-com-1470x705-default-poppins-regular-16 ${theme.colorScheme == "dark" ? "text-white" : "text-black"} text-left inline-block`}>
            <ReactMarkdown>{props.message}</ReactMarkdown>
            </div>
          {/* </div> */}
        </div>

          <div className="h-12 flex relative flex-col items-start justify-start pt-4 pb-0 pl-0 box-border min-w-[150px] mq450:pr-5 mq450:box-border">
            <div className="flex relative flex-row items-center justify-start pl-0 box-border min-w-[150px] mq450:pr-5 mq450:box-border">
              <div className="rounded-t rounded-b-none flex flex-row items-center justify-start p-2">
                <ActionIcon size={"1rem"} onClick={() => handleCopy(props.message)}><Icon name="copy" size="1rem" /></ActionIcon>
                {/* <img
                  className="h-[20.6px] w-3.5 relative overflow-hidden shrink-0 cursor-pointer"
                  alt=""
                  src="/svg-3.svg"
                  onClick={() => handleCopy(props.message)}
                /> */}
              </div>
              {(!streaming && latest && props?.index != 0 && !traceless) && (
              <div className="flex flex-col items-center justify-center px-1 box-border">
                {theme.colorScheme == "light" ? <img
                  onClick={toggleOptions}
                  className="h-[22px] w-[22.1px] relative object-cover cursor-pointer"
                  loading="lazy"
                  alt=""
                  src="/femaleheadsilhouettebeaurifulgirlwomanvector31305525removebgpreview-1@2x.png"
                /> : <img
                onClick={toggleOptions}
                className="h-[22px] w-[22.1px] relative object-cover cursor-pointer"
                loading="lazy"
                alt=""
                src="/female-head-silhouette-beauriful-girl-woman-vector-31305525-removebg-preview.png"
              /> }
              </div>)}
              </div>
              {(!streaming && latest && props?.index != 0 && showOptions && !traceless) && (
                 <div className="max-w-[8rem] ml-10">
                  <ul className={`${theme.colorScheme == "dark" ? " bg-gray-800" : "bg-theme-color-2"} rounded-md p-0 mt-0 mb-2 list-none overflow-hidden`}>
                    {optionButtons.map((item, index) => (
                          <Tooltip label={item.tooltip}>
                           <li className="p-2 text-sm leading-[22px] font-chat-dante-ai-com-1470x705-default-poppins-regular-16 text-center mix-blend-normal hover:bg-theme-color-1 hover:text-theme-color-2 cursor-pointer"
                            onClick={() => {sendMessageToBackend("", item.mode)
                          closeOptions()
                          }}
                          style={{
                            borderBottom: index !== optionButtons.length - 1 ? '0.5px solid #ccc' : 'none'
                          }}
                          >
                        {item.label}
                        </li>
                        </Tooltip>
                       
                    ))}
                  </ul>
                  <div ref={lastMessageRef}/>
              </div>
              )}
            </div>
          </div>
      </div>
    );  
};


  export default BotMessage