import {
  Card,
  Center,
  Container,
  ScrollArea,
  Text,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BotMessage from "./BotMessage/BotMessage";
import UserMessage from "./UserMessage";
import { ChatMessage } from "src/interfaces/Chat";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useMediaQuery } from "@mantine/hooks";
import { optionButtons } from "./BotMessage/metadata";
import useResponsive from "src/hooks/useResponsive";
import ChatInput from "./common/Inputs/ChatMessageInput";
import { COLORS } from "src/utils/styles";
import { useChat } from "src/hooks/useChat";
import useViewportHeight from "src/hooks/useViewPortHeight";

interface ChatArea {
  messages: ChatMessage[];
  streaming: boolean;
  tracelessMessages?: ChatMessage[]
  inputMessage: string
  sendMessage: () => void
  handleStopStream: () => void
  disclaimer: string
  setInputMessage: React.Dispatch<React.SetStateAction<string>>
}

export const ChatArea = (props: ChatArea) => {
  const { messages, streaming, tracelessMessages, inputMessage, setInputMessage, sendMessage, handleStopStream, disclaimer} = props;
  const viewport: any = useRef<HTMLDivElement>(null);
  const [isAtBottomButtonTrigger, setAtBottomButtonTrigger] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const {isMobile, isTabletOnly} = useResponsive()
  const theme = useMantineTheme();
  useViewportHeight()

  useEffect(() => {
   scrollToBottom()
  }, [showOptions])

  useEffect(() => {
    // Scroll to bottom on initial page load
    scrollToBottom()

    const handleScrollButtonTrigger = () => {
      if (!viewport.current) {
        return;
      }
      
      // Calculate the difference and check if it's within a threshold
      const bottomThreshold = isMobile ? 120 : 100; // pixels from the bottom
      const isBottom = viewport.current.scrollHeight - viewport.current.scrollTop <= viewport.current.clientHeight + bottomThreshold;
      setAtBottomButtonTrigger(isBottom);
    };
    
    const handleScroll = () => {
      if (!viewport.current) {
        return;
      }
      
      // Calculate the difference and check if it's within a threshold
      const bottomThreshold = isMobile ? 30 : 50;// pixels from the bottom
      const isBottom = viewport.current.scrollHeight - viewport.current.scrollTop <= viewport.current.clientHeight + bottomThreshold;
      setIsAtBottom(isBottom);
    };

    viewport.current?.addEventListener('scroll', handleScrollButtonTrigger);
    viewport.current?.addEventListener('scroll', handleScroll);

    return () => {
      viewport.current?.removeEventListener('scroll', handleScrollButtonTrigger);
      viewport.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
      if (streaming){
        if (isAtBottom){
          scrollToBottom();
        }
    }
  }, [messages, streaming]);

  const handleOpenOptions = useCallback(() => {
    setShowOptions((prev) => !prev);
  }, [])

  const closeOptions = useCallback(() => {
    setShowOptions(false);
  }, [])

  const scrollToBottom = () => {
    viewport.current.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div 
    style={{height: isMobile ? isTabletOnly ? 'calc(var(--vh, 1vh) * 99)' : 'calc(var(--vh, 1vh) * 98)' : 'calc(100vh - 50px)'}}
    className={`flex flex-col lg:min-w-[800px] lg:max-w-[800px] mx-auto overflow-hidden relative`}>
  
      <div
        ref={viewport}
        className={`flex-1 overflow-y-auto scrollbar-hide p-4`}
      >
          {messages?.map((item, index) => {
            let isLastMessage = index === messages.length - 1;
            if (item.role == "User") {
              return <UserMessage message={item.content} />;
            } else {
              return isLastMessage ? (<BotMessage
                  index={index}
                  showOptions={showOptions}
                  message={item.content}
                  isLatest={isLastMessage}
                  toggleOptions = {handleOpenOptions}
                  closeOptions={closeOptions}
                  streaming={streaming}
                />
              ): (<BotMessage
                index={index}
                message={item.content}
                isLatest={isLastMessage}
                streaming={streaming}
              />)
            }
          })}
          {tracelessMessages?.map((item, index) => {
            let isLastMessage = index === messages.length - 1;
            if (item.role == "User") {
              return <UserMessage traceless message={item.content} />;
            } else {
              return (
                <BotMessage
                  index={index}
                  traceless
                  message={item.content}
                  isLatest={isLastMessage}
                  streaming={streaming}
                />
              );
            }
          })}


      </div>
      {!isAtBottomButtonTrigger && (
        <div className="absolute inset-x-0 bottom-32 flex justify-center">
          <ArrowDownwardIcon
          onClick={scrollToBottom}
          className="cursor-pointer z-50 p-1 rounded-full text-lg text-theme-color-4 bg-theme-color-2"
          style={{ fontSize: '2rem' }}/>
        </div>
      )}
          <div className="flex items-center p-4 pt-0 bg-white">
            <div className= "w-[100%] ">
            <ChatInput
              streaming={streaming}
              onStopStream={handleStopStream}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              onSend={sendMessage}
              disabled={streaming || !inputMessage}
            />
              <div className= 'flex justify-center sm:h-12 md:h-6'>
              <Text style={{textAlign: "center", fontSize: 9, color: theme.colorScheme == "dark" ? COLORS.border : 'black'}}>Disclaimer: {disclaimer}</Text>
              </div>
      </div>

   </div>
   </div>
  );
};
