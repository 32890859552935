import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {  ChatArea } from "src/components/Chat";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import {
  Card,
  Center,
  Container,
  LoadingOverlay,
  Text,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { UserRole } from "src/enums/User";
import { Navigate, useNavigate } from "react-router-dom";
import { getRole } from "src/utils/storage";
import {
  handleUpdateAppSettings,
  handleUserUpdate,
  stopStream,
} from "src/redux/redux-store";
import { ChatStyledContainerInset, StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS } from "src/utils/styles";
import { Notifications } from "@mantine/notifications";
import { useMediaQuery } from "@mantine/hooks";
import ChatInput from "src/components/common/Inputs/ChatMessageInput";
import { useChat } from "src/hooks/useChat";
import { useNotifications } from "src/hooks/useNotifications";
import { ErrorMessage } from "src/components/common/ErrorMessage/ErrorMessage";
import { PubSubNotificationTypes } from "src/enums/Notifications";
import useResponsive from "src/hooks/useResponsive";
import { userRoutes } from "src/navigators/routes/user";


const useStyles = createStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    height: "100%",
  },
  textAreaAndDisclaimer: {
    alignSelf: "center",
    justifySelf: "center",
 
    '@media (min-width: 992px)': {
   
    },
  }
}));

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const client = useNotifications()?.client
  const user = useAppSelector(state => state.user)
  const appSettings = useAppSelector((state) => state.app).appSettings;
  const {backgroundColor, backgroundColorExterior, isMobile, isTabletOnly } = useResponsive()
  
  const {inputMessage, setInputMessage, getMessages, sendMessage, streaming, chatLoading, messages, tracelessMessages} = useChat()

  const checkRole = async () => {
    const role = await getRole();

    if (role == UserRole.admin) {
      return navigate("/admin/", { replace: true });
    }
  };


  useEffect(() => {
    if(client){
      client.on("server-message", (data) => {
        if(data.message.data){
          const message = JSON.parse(data.message.data.toString());

          if(message.event_type == PubSubNotificationTypes.appInfoUpdate){
            dispatch(handleUpdateAppSettings())
          }
        }
      })
    }
  }, [client]);

  useEffect(() => {
    checkRole();
    getAllData();
  }, [])

  const checkFreeTrial = () => {
    if(user.trial_expired && !user.paid){
      navigate(userRoutes.PAYMENT)
    }
  }
  
  const getAllData = useCallback(() => {
    dispatch(handleUserUpdate());
    dispatch(handleUpdateAppSettings());
    checkFreeTrial();
  }, [dispatch]);

  useEffect(() => {
      getMessages()
  }, [appSettings.greeting_message])

  const handleStopStream = useCallback(() => {
    dispatch(stopStream(true));
  }, [dispatch]);

  return (
    <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
      <ChatStyledContainerInset theme={{backgroundColor: backgroundColor}}>
        <Notifications
          position={isMobile ? "top-center" : "bottom-right"}
          limit={isMobile ? 1 : 5}
        />
        <LoadingOverlay visible={chatLoading} />


          {/* <div className={`${isMobile ? 'w-[95%]' : 'w-[650px]'} flex flex-col`}> */}
          <ChatArea disclaimer={appSettings?.disclaimers} sendMessage={sendMessage} handleStopStream={handleStopStream} messages={messages} tracelessMessages={tracelessMessages} streaming={streaming} inputMessage={inputMessage} setInputMessage={setInputMessage}/>
          {/* <Card mah={isMobile ? 130 : 70} p={0} className={classes.textAreaAndDisclaimer} bg={"indigo"}> */}
            {/* <Group mb={"md"}>{renderSuggestedQuestions()}</Group> */}


        
  
      </ChatStyledContainerInset>
    </StyledContainer>
  );
};

export default Dashboard;
