import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Apple, EmailOutlined, Person } from "@mui/icons-material";
import { CheckIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import {
  Group,
  TextInput,
  Button,
  Center,
  Grid,
  Stack,
  Image,
  Text,
  Space,
  createStyles,
  rem,
  useMantineTheme,
  Checkbox,
} from "@mantine/core";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { ErrorKind } from "src/services/services/MainService/apiProblem";
import { AppButton } from "src/components/common/Buttons/AppButton";
import { authRoutes } from "src/navigators/routes/auth";
import { setLoading } from "src/redux/redux-store";
import { COLORS, FONT_SIZES } from "src/utils/styles";

interface Checkbox {
  label: string;
  value: boolean;
  onChange: () => void;
}

const useStyles = createStyles((theme) => ({
  inputField: {
    // padding: rem(14), // Adjust padding as needed
    borderColor: theme.colorScheme == "dark" ? theme.white : theme.black,
  },
}));

const termsAndConditionsUrl = "https://www.unwildered.co.uk/terms_and_conditions"
const privacyPolicyUrl = "https://www.unwildered.co.uk/privacy_policy"

const SPECIAL_CHARACTERS = ['-', '!', '@', '#', '$', '%', '=', ':', '?', '.', '/', '|', '~', '>'];

// Convert the special characters to a regex-safe string
const specialCharactersRegex = `[${SPECIAL_CHARACTERS.map(c => '\\' + c).join('')}]`;

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(new RegExp(specialCharactersRegex), "Password must have at least one special character")
    .matches(/\d/, "Password must have at least one number"),
});

const Signup: FunctionComponent = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [showPassword, setShowPassword] = useState(false);
  const navigation = useNavigate();
  const [error, setError] = useState("");
  const [privacyRead, setPrivacyRead] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validate: yupResolver(schema),
  });

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);


  const signUp = async (values: any) => {
    setLoading(true);
    try {
      const response = await authService.signUp(
        values.name,
        values.email,
        values.password
      );

      if (response.kind == ResponseKind.OK) {
        setError("");
        const data = response.data;

        if (data?.is_active) {
        } else {
          navigation(authRoutes.LOGIN);
          alert("Account activation link has been sent to your email");
        }
      } else if (response.kind == ErrorKind.REJECTION_ERROR) {
        setError(response?.errors);
        throw response.errors;
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center maw={"75vh"} h={"100vh"} mx="auto">
      <Stack className="relative flex" spacing={"lg"} maw={"85%"}>
        <Center>
          <Image
            src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"}
            fit="contain"
          />
        </Center>
        <form onSubmit={form.onSubmit((values) => signUp(values))}>
          <Stack spacing={"sm"}>
            <TextInput placeholder="John Doe" {...form.getInputProps("name")} />

            <TextInput
              placeholder="johndoe@gmail.com"
              rightSection={
                <img
                  src="/mail_box.png"
                  style={{ width: rem(12), height: rem(10) }}
                />
              }
              {...form.getInputProps("email")}
              className={classes.inputField}
            />

            <TextInput
              placeholder="*****"
              type={showPassword ? "text" : "password"}
              rightSection={
                <img
                  src="/view_password.png"
                  style={{ width: rem(14), height: rem(10), cursor: "pointer" }}
                  onClick={togglePasswordVisibility}
                />
              }
              {...form.getInputProps("password")}
            />
          </Stack>
          <Group position="right" mt="xl">
            <AppButton disabled={!privacyRead} loading={loading} text="Sign Up" type="submit" />
          </Group>
        </form>

        <Center className="flex flex-row justify-center items-center gap-3 mt-4">
          <div className="flex-row items-center justify-center flex gap-2">
          <Checkbox
            styles={(theme) => ({label: {fontSize: FONT_SIZES.h3}})}
            color="dark"
            checked={privacyRead}
            onChange={(event) => setPrivacyRead(event.currentTarget.checked)}
          />
          <Text>
          {"I have read and accept the "} {<a className=" text-theme-color-1" href={termsAndConditionsUrl}>terms & conditions</a>} and {<a className=" text-theme-color-1" href={privacyPolicyUrl}>privacy policy</a>}.
          </Text>
          </div>
       
        </Center>
        <Center className="flex flex-row justify-center gap-1">
          <Text style={{fontSize: FONT_SIZES.h3}} align="center">Already have an account?</Text>
          <Text style={{fontSize: FONT_SIZES.h3}} component="a" href="/auth/login" underline>
            Sign In
          </Text>
          <Text style={{ color: theme.colors.red[4] }}>{error}</Text>
        </Center>

        {/* <div className="flex flex-row justify-between items-center mt-4">
        
          
        </div> */}

      
      </Stack>
    </Center>
  );
};

export default Signup;
