import {
  Avatar,
  Burger,
  Button,
  Center,
  Divider,
  Grid,
  Group,
  Drawer,
  Header,
  Image,
  MediaQuery,
  Menu,
  Stack,
  Text,
  rem,
  ActionIcon,
  Container,
  createStyles,
  useMantineTheme,
  useMantineColorScheme,
  Space,
  ScrollArea,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  ArrowDownward,
  ArrowUpward,
  MedicalInformation,
} from "@mui/icons-material";
import { FunctionComponent, useCallback, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { adminRoutes } from "src/navigators/routes/AdminRoutes";
import { authRoutes } from "src/navigators/routes/auth";
import ChatSettings from "src/screens/admin/ChatSettings";
import ConfigureLLM from "src/screens/admin/ConfigureLLM";
import ManageKnowledgeBase from "src/screens/admin/ManageKnowledgeBase";
import OtherSettings from "src/screens/admin/OtherSettings";
import { ResponseKind } from "src/services/services/MainService";
import { authService } from "src/services/services/auth/auth.api";
import { clearToken } from "src/utils/storage";
import ConfigureLLMList from "./DropdownComponents/ConfigureLLMList";
import OtherSettingsList from "./DropdownComponents/OtherSettingsList";
import ChatSettingsList from "./DropdownComponents/ChatSettingsList";
import ManageKnowledgeBaseList from "./DropdownComponents/ManageKnowledgeBaseList";
import styled from "@emotion/styled";
import { UserRole } from "src/enums/User";
import { userRoutes } from "src/navigators/routes/user";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import {
  NavBarContainer,
  NavBarParentContainer,
} from "./common/StyledComponents/NavBarContainer";
import ExpandableList from "./common/Lists/ExpandableList";
import {
  clearMessages,
  resetUser,
  updateChatLoading,
} from "src/redux/redux-store";
import { chatService } from "src/services/services/chat/chat.api";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { confirmDeletionModal } from "./common/ConfirmationModal";
import { IconButton } from "./common/Buttons/IconButton";
import { IconName } from "./common/Icons/Icons/iconRegistry";
import { FocusButton } from "./common/Buttons/FocusButton";
import useResponsive from "src/hooks/useResponsive";
import { UserSettingsModal } from "./UserSettingsModal";
import { ProfileCard } from "./ProfileCard";
import Icon from "./common/Icons/Icons/Icon";
interface TopNavBarAdminProps {
  opened: boolean;
  handleOpen?: () => void;
}

enum buttonKeys {
  dashboard = "dashboard",
  configureLLM = "configureLLM",
  chatSettings = "chatSettings",
  clearChat = "clearChat",
  otherSettings = "otherSettings",
  manageKnowledgebase = "manageKnowledgebase",
}

enum settingKeys {
  clearChat = 1,
  darkMode = 2,
}

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    border: 0,

    // Or directly specifying a breakpoint
    "@media (min-width: 992px)": {
      // Adjustments for devices wider than 768px
    },
  },
}));

const TopNavbarAdmin = (props: TopNavBarAdminProps) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const streaming = useAppSelector((state) => state.chat.streaming);
  const user = useAppSelector((state) => state.user);
  const { logout, role } = useAuth();
  const { opened, handleOpen } = props;

  const { isMobile, backgroundColor, backgroundColorExterior } =
    useResponsive();
  const buttonArrayMatches = useMediaQuery("(max-width: 1440px)");
  const [drawerOpened, { open, close }] = useDisclosure(false);
  const location = useLocation();
  const theme = useMantineTheme();
  const { toggleColorScheme } = useMantineColorScheme();

  if (role == UserRole.user) {
    return <Navigate to={userRoutes.DASHBOARD} replace />;
  }

  const tabActive = (key: string) => {
    return location.pathname.includes(key);
  };

  const logOut = async () => {
    const response = await authService.signOut();

    if (response.kind == ResponseKind.OK) {
      navigate(authRoutes.LOGIN, { replace: true });
      dispatch(resetUser());
      clearToken();
      logout();
    } else {
      clearToken();
      logout();
    }
  };

  const buttons: { icon: IconName; label: string; key: buttonKeys }[] = [
    { key: buttonKeys.dashboard, label: "Dashboard", icon: "home" },
    { key: buttonKeys.configureLLM, label: "Configure LLM", icon: "settings2" },
    { key: buttonKeys.chatSettings, label: "Chat Settings", icon: "message" },
    { key: buttonKeys.otherSettings, label: "Other Settings", icon: "threeDots" },
    { key: buttonKeys.manageKnowledgebase, label: "Manage Knowledge Base", icon: "knowledgebase" },
    // More buttons can be added here
  ];

  const buttonsDropdown = [
    { label: "Configure LLM", renderContent: <ConfigureLLMList /> },
    { label: "Chat Settings", renderContent: <ChatSettingsList /> },
    { label: "Other Settings", renderContent: <OtherSettingsList /> },
    { label: "Manage Knowledge Base", renderContent: <ManageKnowledgeBaseList /> },
    // More buttons can be added here
  ];

  const extractInitials = (userName: string) => {
    if (!userName) return ""; // Return empty if no name is provided

    // Split the name into parts and filter out any empty results from extra spaces
    const nameParts = userName.trim().split(/\s+/);

    // Check if we have at least two parts from which to construct initials
    if (nameParts.length > 1) {
      // Use the first letter of the first and last name parts
      return (
        nameParts[0][0] + nameParts[nameParts.length - 1][0]
      ).toUpperCase();
    } else {
      // If there's only one part, just return the first letter of this part
      return nameParts[0][0].toUpperCase();
    }
  };

  const onHandleButtonPress = (key: buttonKeys) => {
    switch (key) {
      case buttonKeys.dashboard:
        navigate("admin/" + adminRoutes.DASHBOARD);
        return;
      case buttonKeys.clearChat:
        if (!streaming) {
          confirmDeletionModal({
            title: "Clear chat",
            feedback: "You are about to clear all chat history",
            onClose: () => {},
            onConfirm: clearChat,
          });
        }
        return;
      case buttonKeys.configureLLM:
        navigate("admin/" + adminRoutes.CONFIGURE_LLM);
        return;
      case buttonKeys.otherSettings:
        navigate("admin/" + adminRoutes.OTHER_SETTINGS);
        return;
      case buttonKeys.chatSettings:
        navigate("admin/" + adminRoutes.CHAT_SETTINGS);
        return;
      case buttonKeys.manageKnowledgebase:
        navigate("admin/" + adminRoutes.MANAGE_KNOWLEDGEBASE);
        return;
      default:
        navigate("admin/" + adminRoutes.DASHBOARD);
        return;
    }
  };

  const handleSettings = useCallback(
    (key: settingKeys) => {
      switch (key) {
        case settingKeys.clearChat:
          if (!streaming) {
            confirmDeletionModal({
              title: "Clear chat",
              feedback: "You are about to clear all chat history",
              onClose: () => {},
              onConfirm: clearChat,
            });
          }
          return;
        case settingKeys.darkMode:
          toggleColorScheme();
          return;
        default:
          return;
      }
    },
    [streaming]
  );

  const clearChat = async () => {
    try {
      dispatch(updateChatLoading(true));
      const response = await chatService.clearChat();
      if (response.kind == ResponseKind.OK) {
        if (response) {
          dispatch(clearMessages());
        }
      } else {
        throw "Error";
      }
    } catch (e) {
    } finally {
      dispatch(updateChatLoading(false));
    }
  };

  const goToUnwildered = useCallback(() => {
    window.open("https://www.unwildered.co.uk/")
  }, [])

  return isMobile ? (
    <Header className={classes.header} height={"30"}>
      <NavBarParentContainer
        theme={{ backgroundColor: backgroundColorExterior }}
      >
        <NavBarContainer theme={{ backgroundColor: backgroundColor }}>
          <Group position="apart" w={"100%"}>
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <Burger opened={opened} onClick={handleOpen} size="xs" mr="xl" />
            </MediaQuery>
            <Image
              onClick={goToUnwildered}
              width={"9rem"}
              src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"}
            />
            <>
              <ActionIcon onClick={open}>
               <Icon name="settings" size="1rem" /> 
              </ActionIcon>
              <Drawer
                  styles={(theme) => ({
                    content: {
                      backgroundColor: backgroundColor, // Example: a light green background
                    },
                    header: {
                      backgroundColor: backgroundColor, // Example: a light green background
                    },
                    
                  })}
                  opened={drawerOpened}
                  position="right"
                  onClose={close}
                  title="Options"
                  overlayProps={{ opacity: 0.5, blur: 4 }}
                >
                  <Stack spacing="md">
                    <ExpandableList items={buttonsDropdown} />
                    <Center>
                      <Stack
                        w={"100%"}
                        py={"lg"}
                        px={"md"}
                        pos={"static"}
                        bg={backgroundColor}
                        bottom={0}
                      >
                        <IconButton
                          onClick={() => handleSettings(settingKeys.darkMode)}
                          icon={
                            theme.colorScheme == "dark"
                              ? "lightMode"
                              : "darkMode"
                          }
                          text={
                            theme.colorScheme == "dark"
                              ? "Switch to Light Mode"
                              : "Switch To Dark Mode"
                          }
                        />
                        <IconButton
                          onClick={clearChat}
                          icon="clear"
                          text={"Clear Chat"}
                        />
                        <Divider />

                        <IconButton
                          onClick={logOut}
                          icon="logout"
                          text="Logout"
                        />
                      </Stack>
                    </Center>
                  </Stack>

                  {/* Drawer content */}
                </Drawer>
            </>
          </Group>
        </NavBarContainer>
      </NavBarParentContainer>
    </Header>
  ) : (
    <Header className={classes.header} height={"50"}>
      <NavBarParentContainer
        theme={{ backgroundColor: backgroundColorExterior }}
      >
        <NavBarContainer theme={{ backgroundColor: backgroundColor }}>
          <Group position="apart" w={"100%"}>
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <Burger opened={opened} onClick={handleOpen} size="xs" mr="xl" />
            </MediaQuery>
            <Image
              onClick={goToUnwildered}
              width={"12rem"}
              src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"}
            />

            {buttonArrayMatches ? (
              <>
                <ActionIcon onClick={open}>
            
                     <Icon name="settings" size="1.25rem" /> 
                </ActionIcon>
                <Drawer
                  styles={(theme) => ({
                    content: {
                      backgroundColor: backgroundColor, // Example: a light green background
                    },
                    header: {
                      backgroundColor: backgroundColor, // Example: a light green background
                      paddingInline:"1.8rem"
                    },
                    title:{
                      fontSize: FONT_SIZES.h1,
                      fontWeight: 500,
                    }
                  })}
                  opened={drawerOpened}
                  position="right"
                  onClose={close}
                  title="Options"
                  overlayProps={{ opacity: 0.5, blur: 4 }}
                >
                  <Stack spacing="md">
                    <ExpandableList items={buttonsDropdown} />
                    <Center>
                      <Stack
                        w={"100%"}
                        py={"lg"}
                        px={"md"}
                        pos={"static"}
                        bg={backgroundColor}
                        bottom={0}
                      >
                        <IconButton
                          onClick={() => handleSettings(settingKeys.darkMode)}
                          icon={
                            theme.colorScheme == "dark"
                              ? "lightMode"
                              : "darkMode"
                          }
                          text={
                            theme.colorScheme == "dark"
                              ? "Switch to Light Mode"
                              : "Switch To Dark Mode"
                          }
                        />
                        <IconButton
                          onClick={clearChat}
                          icon="clear"
                          text={"Clear Chat"}
                        />
                        <Divider />

                        <IconButton
                          onClick={logOut}
                          icon="logout"
                          text="Logout"
                        />
                      </Stack>
                    </Center>
                  </Stack>

                  {/* Drawer content */}
                </Drawer>
              </>
            ) : (
              <Group>
                <Group style={{ borderRadius: 10 }} spacing="sm">
                  {buttons.map((button) => (
                    <div className="w-max">
                      <FocusButton
                        icon={button.icon}
                        text={button.label}
                        focused={tabActive(button.key)}
                        onClick={() => onHandleButtonPress(button.key)}
                      />
                    </div>
                  ))}
                </Group>
                <UserSettingsModal
                  userName={user}
                  logOut={logOut}
                  switchToDarkTheme={() => handleSettings(settingKeys.darkMode)}
                  clearChat={() => handleSettings(settingKeys.clearChat)}
                />
              </Group>
            )}
          </Group>
        </NavBarContainer>
      </NavBarParentContainer>
    </Header>
  );
};

export default TopNavbarAdmin;
