import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Container,
  FileButton,
  Group,
  Image,
  List,
  Loader,
  LoadingOverlay,
  MantineTheme,
  Modal,
  Navbar,
  Stack,
  Text,
  createStyles,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  Info,
  Facebook,
  FacebookRounded,
  Google,
  StarsTwoTone,
} from "@mui/icons-material";
import UploadDocs from "./UploadDocs";
import { useDocuments } from "src/hooks/useDocuments";
import { useAppSelector } from "src/redux/redux-store/hooks";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentContainerType } from "src/enums/Documents";

import FailedFilesDrawer from "./FailedFilesDrawer";
import UploadFilesDrawer from "./UploadFilesDrawer";
import { ErrorMessage } from "./common/ErrorMessage/ErrorMessage";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import { useNotifications } from "src/hooks/useNotifications";
import { butttonStylesNoIcon } from "./common/GlobalStyles";
import Icon from "./common/Icons/Icons/Icon";
import { userRoutes } from "src/navigators/routes/user";
import { paymentService } from "src/services/services/payment/payment.api";
import { ResponseKind } from "src/services/services/MainService";
import BaseTable from "./Tables/BaseTable";
import { UserRole } from "src/enums/User";
import { UserStatusCard } from "./Cards/UserStatusCard";
import { AdminStatusCard } from "./Cards/AdminStatusCard";
// import { appInsights } from "src/ApplicationInsights";
import useViewportHeight from "src/hooks/useViewPortHeight";

interface SideBarProps {
  opened: boolean;
}

const useStyles = createStyles((theme) => ({
  parentContainer: {
    height: 'calc(var(--vh, 1vh) * 99 - 35)',
    display: "flex", // Makes the navbar a flex container
    flexDirection: "column", // Stacks children vertically
    justifyContent: "center", // Centers children vertically in the container
    backgroundColor: theme.colorScheme == "dark" ? "black" : "white",
    "@media (min-width: 1024px)": {
      height: "100vh",
      borderRadius: "0 0 0 0",
    },
  },
  navbar: {
    flex: 1,
    overflow: 'auto',
    display: "flex", // Makes the navbar a flex container
    flexDirection: "column",
    "@media (min-width: 1024px)": {
      // height: "100%",
      borderRadius: "0 0 0 0",
    },
    // height: "100%",
    backgroundColor: theme.colorScheme == "dark" ? "black" : theme.white,
  },

  navbarFooter: {
    "@media (max-width: 767px)": {},
  },
  button: {
    borderRadius: "0.25rem",
    fontSize: "0.75rem",
    height: rem(30),
    borderWidth: 0.5,
  },
}));

const Sidebar = (props: SideBarProps) => {
  const { opened } = props;
  const { classes } = useStyles();
  const clientStarted = useNotifications()?.started;
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const theme = useMantineTheme();
  useViewportHeight()
  const trial_days = user.trial_days;
  const [paymentUrl, setPaymentUrl] = useState("");

  const {
    files,
    loading,
    deleteFiles,
    downloadFile,
    uploadFiles,
    setUploadedFiles,
    selectedFiles,
    deleteSingleFile,
    uploadQueue,
    setSelectedFiles,
    error,
    dismissError,
    clearQueue,
    failFiles,
    uploadDriveFile,
    getDocs,
  } = useDocuments({ container: DocumentContainerType.document });

  const navigateToPayments = useCallback(async () => {
    if (user.paid) {
      const response = await paymentService.createPortalSession();

      if (response.kind == ResponseKind.OK && response.data) {
        setPaymentUrl(response.data);
        return response.data;
      }
     
    }
    navigate(userRoutes.PAYMENT);
  }, [navigate, user]);

  const handleButtonClick = async () => {
    const url = await navigateToPayments();
    if (url) {
      window.location.href = url;
    }
  };

  const goToFAQs = useCallback(() => {
    window.open("https://www.unwildered.co.uk/how-it-works");
  }, []);

  const goToAboutUs = useCallback(() => {
    window.open("https://www.unwildered.co.uk/about");
  }, []);

  return (
    <Navbar
      hiddenBreakpoint={992}
      withBorder={false}
      px={"xs"}
      className={classes.parentContainer}
      hidden={!opened}
      width={{
        md: 350,
      }}
    >
      <div className={classes.navbar}>
        <ErrorMessage error={error} handleClose={dismissError} />

        <UploadFilesDrawer onPressClose={clearQueue} files={uploadQueue} />

        <Navbar.Section px={"xs"}>
          <Text my={"sm"} fw={600} style={{ fontSize: FONT_SIZES.h1 }}>
            My Documents
          </Text>
          <Card
            p={"0.25rem"}
            w={"100%"}
            withBorder
            style={{ backgroundColor: theme.colors.blue[1] }}
          >
            <Group noWrap={true}>
              <Icon name="info" size="1rem" color="black" />
              <Text style={{ color: "black" }} size={FONT_SIZES.text} fw={500}>
                Upload relevant documents for a more personalised response from
                Caira
              </Text>
            </Group>
          </Card>
        </Navbar.Section>
        {/* {<LoadingOverlay visible={loading || !clientStarted} />} */}
        {<LoadingOverlay visible={loading} />}
        <Navbar.Section grow px="xs">
          <></>
          {/* <UploadDocs
            setSelectedFiles={setSelectedFiles}
            uploadedFiles={uploadFiles}
            setUploadedFiles={setUploadedFiles}
            deleteSingleFile={deleteSingleFile}
            width={"90%"}
            onRefresh={getDocs}
            selectedFiles={selectedFiles}
            uploadDriveFile={uploadDriveFile}
            onPressDelete={deleteFiles}
            onPressDownload={downloadFile}
            files={files}
          /> */}
        </Navbar.Section>

        <Navbar.Section className={classes.navbarFooter}>
          <Center>
            <Button.Group w={"95%"} orientation="vertical">
              <Button my={"md"} onClick={goToFAQs} styles={butttonStylesNoIcon}>
                <Text align="center" size={FONT_SIZES.text}>
                  How it works
                </Text>
              </Button>
              <Button my={"md"} onClick={goToAboutUs} styles={butttonStylesNoIcon}>
                <Text size={FONT_SIZES.text}>Contact Us</Text>
              </Button>
              <Center>
                {user.role == UserRole.user ? (
                  <UserStatusCard
                    paid={user?.paid || false}
                    handlePayment={handleButtonClick}
                    trialDays={user.trial_days}
                  />
                ) : (
                  <AdminStatusCard />
                )}
              </Center>
            </Button.Group>
          </Center>
        </Navbar.Section>
      </div>
    </Navbar>
  );
};

export default Sidebar;
