import { FunctionComponent, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { chatService } from "src/services/services/chat/chat.api";
import { ResponseKind } from "src/services/services/MainService";
import { handleUpdateAppSettings, handleUserUpdate, stopStream, updateChatList, updateChatLoading, updateSuggestedQuestionsList } from "src/redux/redux-store";
import { Card, Center, Container, LoadingOverlay, Text, createStyles, useMantineTheme } from "@mantine/core";
import { useAuth } from "src/hooks/useAuth";
import { UserRole } from "src/enums/User";
import { Navigate, useNavigate } from "react-router-dom";
import { ChatMessage } from "src/interfaces/Chat";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { userRoutes } from "src/navigators/routes/user";
import { getRole } from "src/utils/storage";
import { ChatStyledContainerInset, StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import { COLORS } from "src/utils/styles";
import { Notifications } from "@mantine/notifications";
import { useMediaQuery } from "@mantine/hooks";
import { useChat } from "src/hooks/useChat";
import { ChatArea } from "src/components/Chat";
import ChatInput from "src/components/common/Inputs/ChatMessageInput";
import { useNotifications } from "src/hooks/useNotifications";
import { PubSubNotificationTypes } from "src/enums/Notifications";
import useAdminSettings from "src/hooks/useAdminSettings";
import useResponsive from "src/hooks/useResponsive";

const useStyles = createStyles((theme) => ({
  parentContainer: {
    display: "flex",
    
    flexDirection: "column",
    justifyItems: "center",
    '@media (min-width: 992px)': {
    },
  },
  textAreaAndDisclaimer: {
    width: "105%",
    alignSelf: "center",
    justifySelf: "center",
    
  }
}));

const AdminDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const appSettings = useAppSelector((state) => state.app).appSettings;
  const theme = useMantineTheme();
  const client = useNotifications()?.client
  const {getSettings} = useAdminSettings()
  const {isMobile, backgroundColor, backgroundColorExterior} = useResponsive()
  
  const {inputMessage, setInputMessage, getMessages, sendMessage, streaming, chatLoading, messages, tracelessMessages} = useChat()

  const checkRole = async () => {
    const role = await getRole()
    
    if(role == UserRole.user){

      return navigate(userRoutes.DASHBOARD, {"replace": true});
    }
  }

  useEffect(() => {
    if(client){
      client.on("server-message", (data) => {
        if(data.message.data){
          const message = JSON.parse(data.message.data.toString());

          if(message.event_type == PubSubNotificationTypes.appInfoUpdate){
            getSettings()
            dispatch(handleUpdateAppSettings())
          }
        }
      })
    }
  }, [client]);

  useEffect(() => {
    checkRole()
    getAllData()
  }, [])

  useEffect(() => {
    getMessages()
  }, [appSettings.greeting_message])

  const getAllData = useCallback(() => {
    dispatch(handleUserUpdate());
    dispatch(handleUpdateAppSettings());
  }, [dispatch]);

  const handleStopStream = useCallback(() => {
    dispatch(stopStream(true));
  }, [dispatch]);

return <StyledContainer theme={{backgroundColor: backgroundColorExterior}}>
  <ChatStyledContainerInset theme={{backgroundColor: backgroundColor}}>
  <Notifications 
    position={isMobile ? "top-center" : "bottom-right"} 
    limit={isMobile ? 1 : 5}
  />
  <LoadingOverlay visible={chatLoading} /> 
  {/* <Container miw={"60%"} className={classes.parentContainer}> */}
        <ChatArea disclaimer={appSettings.disclaimers} sendMessage={sendMessage} handleStopStream={handleStopStream} inputMessage={inputMessage} setInputMessage={setInputMessage} messages={messages} tracelessMessages={[]} streaming={streaming}/>
        {/* <Card mih={isMobile ? 130 : 70} px={0} py={isMobile ? 2 : 0} className={classes.textAreaAndDisclaimer} bg={backgroundColor}>
          <Group mb={"md"}>{renderSuggestedQuestions()}</Group>
          <ChatInput
            streaming={streaming}
            onStopStream={handleStopStream}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            onSend={sendMessage}
            disabled={streaming || !inputMessage}
          />
          <Center style={{marginBottom: "0.25rem"}}>
          <Text size={10} style={{textAlign: "center", color: theme.colorScheme == "dark" ? COLORS.border : "black"}}>Disclaimer: {appSettings?.disclaimers}</Text>
          </Center>
        </Card> */}
        {/* </Container> */}
  </ChatStyledContainerInset>
 </StyledContainer>;
};

export default AdminDashboard;
